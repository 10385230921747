import React, {Component} from 'react';

class Notfound extends Component {
    render() {
        return (
            <div>
                Not Found
            </div>
        );
    }
}

export default Notfound;